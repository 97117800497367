import { CommonButton } from '@Components/common/Button';
import Link from '@Components/common/Link';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import styled, { css } from 'styled-components';
import { mq, rgba } from 'stylesheets/utils';

export const StyledRate = styled(Rate)`
  &.rc-rate {
    height: 16px;
    font-size: ${({ theme }) => theme.fontsizes.body14};
    transform: translateY(-3px) /* rtl: none */;
    .rc-rate-star {
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
export const CarName = styled.div`
  white-space: nowrap;
  line-height: 1.3;
  font-size: 1.3rem;
  height: ${13 * 1.3}px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
`;
export const Card = styled(Link)`
  max-width: 240px;
  min-height: 377px;
  max-height: 377px;
  min-width: 240px;
  max-width: 240px;
  border-radius: 4px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.lightenPurple};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 7px 0px ${({ theme }) => rgba(theme.colors.black2, 0.16)};
  display: flex;
  flex-direction: column;
  ${mq(576)} {
    min-width: calc((100vw - 30px) / 2);
    max-width: calc((100vw - 30px) / 2);
    min-height: 340px;
    max-height: 340px;
  }
`;

export const CardImage = styled.div`
  width: 100%;
  max-height: 152px;
  min-height: 152px;
  height: 152px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuBorder};
  overflow: hidden;
  position: relative;
  user-select: none;
`;

export const CardContent = styled.div`
  padding: 10px 12px 16px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  flex: 1;
  ${mq(576)} {
    padding: 8px;
  }
`;

export const HorizontalDisplay = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${mq(576)} {
    margin-bottom: 8px;
  }
`;

export const CardAction = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  ${mq(576)} {
    gap: 2px;
  }
`;

export const Brand = styled.div`
  display: block;
  width: 100%;
  max-width: 142px;
  max-height: 34px;
  min-height: 23px;
  height: 34px;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  img {
    object-position: left center;
  }
  ${mq(576)} {
    margin-bottom: 4px;
  }
`;

export const Dropdown = styled.img`
  display: inline-block;
  margin-left: auto;
  cursor: pointer;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  color: ${({ theme }) => theme.colors.black};
  height: calc(14px * 1.5 * 2);
  width: 100%;
  overflow: hidden;
  margin-bottom: 8px;
  /* ${mq(576)} {
    height: calc(12px * 1.5 * 2);
  } */
`;

export const StarRate = styled.div`
  margin-right: 4px;
  display: flex;
  width: 16px;
  height: 16px;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const Price = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body16};
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  color: ${({ theme }) => theme.colors.black};
`;

export const Info = styled.p<{
  readonly variant: 'default' | 'line-throw';
}>`
  font-size: ${({ theme }) => theme.fontsizes.body12};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  color: ${({ theme }) => theme.colors.lightPurple};
  position: relative;
  line-height: 0.9;
  margin-left: 14px;
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightPurple};
    opacity: 0;
  }
  ${({ variant, theme }) =>
    variant === 'line-throw'
      ? css`
          margin-left: 8px;
          &::after {
            opacity: 1;
          }
        `
      : css``}
`;

export const Badge = styled.span`
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: ${({ theme }) => theme.colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 1.4rem;
  white-space: nowrap;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: ${({ theme }) => theme.colors.black2};
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  z-index: 5;
`;

export const AddToCard = styled(CommonButton)`
  padding: 0;
  max-width: 160px;
  min-width: 160px;
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 24px;
  ${mq(576)} {
    max-width: none;
    min-width: unset;
    padding: 0 8px;
    flex: 1;
    font-size: 1.2rem;
  }
`;
export const AddToCartInner = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  & > * {
    min-width: 23.25px;
    ${mq(576)} {
      min-width: 18.25px;
    }
  }
  ${mq(576)} {
    gap: 10px;
  }
`;
export const Like = styled(CommonButton)`
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.lightenPurple};
  padding: 0;
`;

export const QuantityWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  font-size: 2rem;
  font-weight: 500;
  user-select: none;
`;
export const QuantityBtn = styled(CommonButton)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  height: 36px;
  min-width: 46px;
  max-width: 46px;
  ${mq(576)} {
    font-size: 1.4rem;
    min-width: 30px;
    max-width: 30px;
  }
`;
